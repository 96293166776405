import { CopyIcon } from "@chakra-ui/icons";
import {
  Box,
  Text,
  Flex,
  IconButton,
  Tooltip,
  Button,
  useColorMode,
} from "@chakra-ui/react";
import { useState } from "react";
import { CodeBlock as Code, dracula } from "react-code-blocks";
import customTheme from "../../customTheme";

function CodeBlock({ request, url, code }) {
  const [copied, setCopied] = useState(false);

  const { colorMode } = useColorMode();

  return (
    <Box
      borderRadius={10}
      maxHeight="40vh"
      overflow={"auto"}
      borderColor={customTheme.colors.darkMode.mediumBorder}
      width="600px"
    >
      <Flex
        backgroundColor={customTheme.colors.lightMode.codeBlockHeader}
        direction={"column"}
        padding={5}
        height="20%"
        justify="center"
        borderTopRadius={10}
      >
        <Flex direction="row" gap={2} alignItems="center" height="1vh">
          <Text
            color="#7FCB92"
            fontFamily={"Open Sans"}
            fontSize="sm"
            fontWeight={"semibold"}
            overflow="auto"
          >
            {request}
          </Text>
          <Text
            color="#9FA8B6"
            fontFamily={"Open Sans"}
            fontSize="sm"
            fontWeight={"normal"}
            align={"left"}
          >
            {url}
          </Text>
          <Tooltip
            closeOnClick={false}
            label={copied ? "Copied" : "Copy to clipboard"}
          >
            <IconButton
              ml="auto"
              size="xs"
              variant="outline"
              colorScheme={colorMode === "light" ? "whiteAlpha" : "white"}
              onClick={() => {
                setCopied((oldCopied) => {
                  if (!oldCopied) {
                    return !oldCopied;
                  } else {
                    return oldCopied;
                  }
                });
                navigator.clipboard.writeText(code.split("\n").join(""));
              }}
              icon={<CopyIcon />}
            />
          </Tooltip>
        </Flex>
      </Flex>
      <Code
        theme={dracula}
        customStyle={{
          backgroundColor: customTheme.colors.lightMode.codeBlockBackground,
          fontFamily: "Roboto Mono",
          fontSize: 14,
        }}
        text={code}
        language={"bash"}
        showLineNumbers={false}
        wrapLongLines={true}
        wrapLines={true}
      />
    </Box>
  );
}

export default CodeBlock;
