//TODO: integrate with Chakra theme file
const customTheme = {
  colors: {
    lightMode: {
      background: "white",
      codeBlockBackground: "#454B64",
      codeBlockHeader: "#3C4257",
      responseBlockBackground: "#F7FAFC",
      responseBlockHeader: "#E3E8EE",
      lightText: "",
      mediumText: "grey",
      darkText: "",
      lightBorder: "",
      mediumBorder: "#CCCCCC",
      darkBorder: "",
    },
    darkMode: {
      background: "#1B202B",
      codeBlockBackground: "#454B64",
      codeBlockHeader: "#3C4257",
      responseBlockBackground: "#454B64",
      responseBlockHeader: "#3C4257",
      lightText: "",
      mediumText: "grey",
      darkText: "",
      lightBorder: "",
      mediumBorder: "#404040",
      darkBorder: "",
    },
  },
  fonts: {},
};

export default customTheme;
