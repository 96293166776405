import {
  Box,
  Text,
  Flex,
  FormControl,
  Spinner,
  IconButton,
  useColorMode,
  Tooltip,
  Switch,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import {
  CodeBlock as Code,
  atomOneLight,
  atomOneDark,
} from "react-code-blocks";
import { useState } from "react";
import customTheme from "../../customTheme";

function ResponseBlock({
  title = "RESPONSE",
  response,
  enableToggle = false,
  isLoading = false,
}) {
  const [responseSwitch, setResponseSwitch] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box
      borderRadius={10}
      borderColor={customTheme.colors.darkMode.mediumBorder}
      width="600px"
    >
      <Flex
        direction={"column"}
        padding={5}
        justify="center"
        backgroundColor={
          colorMode === "light"
            ? customTheme.colors.lightMode.responseBlockHeader
            : customTheme.colors.darkMode.responseBlockHeader
        }
        height="25px"
        borderTopRadius={10}
      >
        <Flex direction="row" alignItems="center">
          <Text
            color="#717B8D"
            fontFamily={"Open Sans"}
            fontSize="sm"
            fontWeight={"semibold"}
            flex={1}
          >
            {title}
          </Text>
          {title.toLowerCase() === "response" && (
            <Flex gap={5} justifyContent="flex-end">
              <FormControl
                display="flex"
                gap={3}
                // flex={1}
                // justifyContent="flex-end"
                alignItems={"center"}
              >
                <Text
                  fontFamily={"Open Sans"}
                  fontSize="sm"
                  opacity={enableToggle ? 1 : 0.3}
                  color="grey"
                >
                  Show error response
                </Text>
                <Tooltip
                  closeOnClick={true}
                  closeOnMouseDown={true}
                  label={enableToggle ? "" : "No error response available"}
                >
                  <Switch
                    isDisabled={!enableToggle}
                    value={responseSwitch}
                    onChange={() =>
                      setResponseSwitch((oldSwitchValue) => !oldSwitchValue)
                    }
                  />
                </Tooltip>
              </FormControl>
              <Tooltip closeOnClick={false} label={"Download response"}>
                <a
                  href={`data:text/json;charset=utf-8,${encodeURIComponent(
                    enableToggle
                      ? JSON.stringify(
                          Object.values(response)[responseSwitch ? 1 : 0],
                          null,
                          2
                        )
                      : response
                  )}`}
                  download="response.json"
                >
                  <IconButton
                    ml="auto"
                    size="xs"
                    variant="outline"
                    colorScheme={colorMode === "light" ? "blackAlpha" : "white"}
                    icon={<DownloadIcon />}
                  />
                </a>
              </Tooltip>
            </Flex>
          )}
          {title.toLowerCase() === "example" && (
            <Flex justifyContent="flex-end">
              <Tooltip closeOnClick={false} label={"Download example"}>
                <a
                  href={`data:text/json;charset=utf-8,${encodeURIComponent(
                    response
                  )}`}
                  download="example.json"
                >
                  <IconButton
                    ml="auto"
                    size="xs"
                    variant="outline"
                    colorScheme={colorMode === "light" ? "blackAlpha" : "white"}
                    icon={<DownloadIcon />}
                  />
                </a>
              </Tooltip>
            </Flex>
          )}
        </Flex>
      </Flex>
      {isLoading ? (
        <Flex justify={"center"} alignItems="center">
          <Spinner my={10} />
        </Flex>
      ) : (
        <Code
          theme={colorMode === "light" ? atomOneLight : atomOneDark}
          customStyle={{
            maxHeight: "475px",
            backgroundColor:
              colorMode === "light"
                ? customTheme.colors.lightMode.responseBlockBackground
                : customTheme.colors.darkMode.responseBlockBackground,
            overflowY: "auto",
            overflowX: "auto",
            fontFamily: "Roboto Mono",
            fontSize: 14
          }}
          text={
            enableToggle
              ? JSON.stringify(
                  Object.values(response)[responseSwitch ? 1 : 0],
                  null,
                  2
                )
              : response
          }
          language={"json"}
          showLineNumbers={false}
          wrapLines={true}
        />
      )}
    </Box>
  );
}

export default ResponseBlock;
