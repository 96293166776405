import {
  Text,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Flex,
  Tab,
  FormControl,
  Textarea,
  IconButton,
  Tooltip,
  Button,
  InputGroup,
  Input,
  InputRightElement,
} from "@chakra-ui/react";
import CodeBlock from "../common/CodeBlock";
import ResponseBlock from "../common/ResponseBlock";
import ParameterField from "../common/parameters/ParameterField";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

function OperationTabs({
  isDesktopOrLaptop,
  url,
  request,
  operation,
  sampleRequestBody,
  setSampleRequestBody,
  showPassword,
  authToken,
  setAuthToken,
  setShowPassword,
  paramList,
  handleParamChange,
  handleSubmit,
  patchSuccess,
  patchData,
  postSuccess,
  isFetching,
  postFetching,
  patchFetching,
  data,
  filteredPatchError,
  filteredPostData,
  filteredPostError,
}) {
  return (
    <Flex flex={1} mt={5} alignSelf={isDesktopOrLaptop ? "auto" : "center"}>
      <Tabs isFitted>
        <TabList>
          <Tab>Example</Tab>
          <Tab>Try It Out</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Flex direction="column" flex={1} gap={3} height="100%">
              <CodeBlock
                url={url}
                request={request}
                //TODO: this wont always be application/json - test with multipart formdata
                code={`curl --location --request ${request.toUpperCase()} \n 'https://api.paperlessparts.com${url}' \n --header 'Authorization: API-Token <api_token>' ${
                  operation?.requestBody
                    ? ` \n --header 'Content-Type: application/json' \n --data-raw '${sampleRequestBody.replace(
                        "\n",
                        ""
                      )}'`
                    : ``
                }`}
              />
              <ResponseBlock
                response={
                  Object.keys(operation.responses).length === 2
                    ? operation.responses
                    : JSON.stringify(operation.responses, null, 2)
                }
                enableToggle={Object.keys(operation.responses).length === 2}
              />
            </Flex>
          </TabPanel>
          <TabPanel>
            <Flex direction="column" flex={1} gap={3} height="100%">
              {operation?.parameters && (
                <>
                  {operation.parameters.map((param, index) => (
                    <ParameterField
                      param={param}
                      paramList={paramList}
                      handleParamChange={handleParamChange}
                    />
                  ))}
                </>
              )}
              {operation?.requestBody && (
                <Flex direction={"row"} alignItems={"baseline"}>
                  <Text
                    fontFamily={"Roboto Mono"}
                    fontSize="sm"
                    fontWeight={700}
                    flex={1}
                  >
                    Request Body
                  </Text>
                  <FormControl flex={1}>
                    <Textarea
                      height="30vh"
                      fontFamily={"Roboto Mono"}
                      fontSize="sm"
                      fontWeight={400}
                      value={sampleRequestBody}
                      // value={JSON.stringify(sampleRequestBody, null, 2)}
                      onChange={(event) =>
                        setSampleRequestBody(event.target.value)
                      }
                    />
                  </FormControl>
                </Flex>
              )}
              <Flex direction={"row"} alignItems={"baseline"}>
                <Text
                  fontFamily={"Roboto Mono"}
                  fontSize="sm"
                  fontWeight={700}
                  flex={1}
                >
                  Authorization Token
                </Text>
                <InputGroup flex={1}>
                  <Input
                    type={showPassword ? "text" : "password"}
                    value={authToken}
                    onChange={(event) => setAuthToken(event.target.value)}
                  />
                  <InputRightElement>
                    <IconButton
                      size="sm"
                      onClick={() => setShowPassword((old) => !old)}
                      icon={showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    />
                  </InputRightElement>
                </InputGroup>
              </Flex>
              <Tooltip
                shouldWrapChildren
                label={
                  paramList.filter((item) => item.required && item.value === "")
                    .length > 0 || authToken === ""
                    ? "Please fill out all required fields"
                    : ""
                }
              >
                <Button
                  colorScheme={"blue"}
                  style={{
                    width: "100%",
                    opacity:
                      paramList.filter(
                        (item) => item.required && item.value === ""
                      ).length > 0 || authToken === ""
                        ? 0.45
                        : 0.85,
                  }}
                  onClick={handleSubmit}
                  isDisabled={
                    paramList.filter(
                      (item) => item.required && item.value === ""
                    ).length > 0 || authToken === ""
                  }
                >
                  Submit
                </Button>
              </Tooltip>
              <ResponseBlock
                isLoading={isFetching || postFetching || patchFetching}
                response={
                  request.toLowerCase() === "get"
                    ? data
                      ? JSON.stringify(data, null, 2)
                      : "Not response yet"
                    : request.toLowerCase() === "patch"
                    ? patchSuccess
                      ? JSON.stringify(patchData, null, 2)
                      : JSON.stringify(filteredPatchError, null, 2)
                    : request.toLowerCase() === "post"
                    ? postSuccess
                      ? JSON.stringify(filteredPostData, null, 2)
                      : JSON.stringify(filteredPostError, null, 2)
                    : "No response data"
                }
              />
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default OperationTabs;
