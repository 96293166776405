import {
  Heading,
  Text,
  Flex,
  Box,
  Divider,
  Button,
  useColorMode,
} from "@chakra-ui/react";
import ResponseBlock from "./ResponseBlock";
import ReactMarkdown from "react-markdown";
import yamlFile from "../../openapi.yaml";

function HeaderPage({
  title,
  description,
  blockTitle,
  blockContent,
  apiVersion,
  openApiVersion,
  innerRef,
  isDesktopOrLaptop,
}) {
  const { colorMode } = useColorMode();

  return (
    <Flex
      direction="column"
      gap={7}
      padding={10}
      ref={innerRef}
      id="#introduction"
    >
      <Flex direction="row" justify={"space-between"} align="center">
        <Heading size="lg" fontFamily={"Open Sans"} fontWeight={"bold"}>
          {title}
        </Heading>
        <a href={yamlFile} download="openapi.yaml" target="_blank">
          <Button
            colorScheme={colorMode === "light" ? "blackAlpha" : "white"}
            variant="outline"
            size="sm"
          >
            Download openapi.yaml
          </Button>
        </a>
      </Flex>
      <Flex direction={isDesktopOrLaptop ? "row" : "column"} gap={10}>
        <Flex direction="column" flex={1}>
          <Text fontSize="md" fontFamily={"Open Sans"}>
            {description.split("\n")[0]}
          </Text>
        </Flex>
        <Flex
          gap={5}
          direction="column"
          alignSelf={isDesktopOrLaptop ? "auto" : "center"}
        >
          {blockTitle && blockContent && (
            <Box flex={1}>
              <ResponseBlock title={blockTitle} response={blockContent} />
            </Box>
          )}
          <Box flex={1}>
            <ResponseBlock
              title={"VERSIONS"}
              response={`Paperless Parts Version: ${apiVersion} \nOpenAPI Version: ${openApiVersion}`}
            />
          </Box>
        </Flex>
      </Flex>
      <ReactMarkdown children={description.split("\n").slice(1).join("\n")} />
      <Divider />
    </Flex>
  );
}

export default HeaderPage;
