import { CopyIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Flex,
  Input,
  Button,
  InputGroup,
  InputLeftElement,
  Divider,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { CodeBlock as Code, dracula } from "react-code-blocks";
import { useEffect } from "react";
import SchemaPage from "../schemas/SchemaPage";

function SearchModal({ api, isOpen, onClose, handleSearchSelection }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);

  const handleSearchTerm = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClick = (type, index) => {
    handleSearchSelection(type, index);
  };

  useEffect(() => {
    const tempResults = [];
    if (api?.components["schemas"]) {
      Object.keys(api.components["schemas"]).map((schema, index) => {
        let found = schema.toLowerCase().includes(searchTerm.toLowerCase());
        if (found) {
          tempResults.push({
            name: schema,
            type: "schema",
            description: Object.values(api.components["schemas"]).at(index)
              .description,
            index: index,
          });
        }
      });
    }
    if (api?.paths) {
      Object.keys(api["paths"]).map((pathKey) => {
        Object.keys(api["paths"][pathKey]).map((requestKey) => {
          if (
            api["paths"][pathKey][requestKey]?.summary &&
            api["paths"][pathKey][requestKey]?.description
          ) {
            let foundEndpoint =
              api["paths"][pathKey][requestKey]?.summary.includes(searchTerm) ||
              api["paths"][pathKey][requestKey]?.description.includes(
                searchTerm
              ) ||
              pathKey.includes(searchTerm);
            if (foundEndpoint) {
              tempResults.push({
                name: pathKey,
                type: "endpoint",
                description: api["paths"][pathKey][requestKey].summary,
                index: api.tags
                  .map((tag) => tag.name.toLowerCase())
                  .indexOf(
                    api["paths"][pathKey][requestKey].tags[0].toLowerCase()
                  ),
              });
            }
          }
        });
      });
    }

    setResults(tempResults);
  }, [searchTerm]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxHeight={"70vh"} overflow="scroll">
        <ModalBody>
          <Flex direction="column" gap={3}>
            <InputGroup>
              <InputLeftElement>
                <SearchIcon />
              </InputLeftElement>
              <Input value={searchTerm} onChange={handleSearchTerm} />
            </InputGroup>
            <Divider />
            {results.map((result) => (
              <Button
                width="100%"
                variant="ghost"
                justifyContent="space-between"
                height="8vh"
                onClick={() => handleClick(result.type, result.index)}
              >
                <Flex direction="column" gap={1} overflow="hidden">
                  <Flex
                    gap={1}
                    align={"center"}
                    direction="row"
                    overflow={"hidden"}
                  >
                    <Text
                      fontFamily={"Roboto Mono"}
                      fontSize="sm"
                      fontWeight={700}
                      textAlign={"left"}
                    >
                      {result.name}
                    </Text>
                    <Text
                      fontFamily={"Open Sans"}
                      fontWeight={"bold"}
                      fontSize="xs"
                      color={"grey"}
                      textAlign={"left"}
                    >
                      {result.type}
                    </Text>
                  </Flex>
                  <Text
                    fontSize="sm"
                    fontFamily={"Open Sans"}
                    fontWeight={400}
                    textAlign={"left"}
                  >
                    {result.description}
                  </Text>
                </Flex>
                <ArrowForwardIcon />
              </Button>
            ))}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default SearchModal;
