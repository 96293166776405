import { Heading, Text, Flex, Box, Divider } from "@chakra-ui/react";
import { CodeBlock as Code, dracula } from "react-code-blocks";
import ResponseBlock from "./ResponseBlock";

function TitlePage({
  title,
  description,
  blockTitle,
  blockContent,
  innerRef,
  isDesktopOrLaptop,
}) {
  return (
    <Flex direction="column" gap={7} pt={10} pr={10} pl={10} ref={innerRef}>
      <Heading size="md" fontFamily={"Open Sans"} fontWeight={"bold"}>
        {title}
      </Heading>
      <Flex direction={isDesktopOrLaptop ? "row" : "column"} gap={10}>
        <Flex direction="column" flex={1}>
          <Text fontSize="md" fontFamily={"Open Sans"}>
            {description}
          </Text>
        </Flex>
        {blockTitle && blockContent && (
          <Box flex={1} alignSelf={isDesktopOrLaptop ? "auto" : "center"}>
            <ResponseBlock title={blockTitle} response={blockContent} />
          </Box>
        )}
      </Flex>
      <Divider />
    </Flex>
  );
}

export default TitlePage;
