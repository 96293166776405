import { Text, Flex, Divider } from "@chakra-ui/react";

function RequiredParameter({ param, index, paramLength }) {
  return (
    <>
      <Flex gap={1} align={"center"}>
        <Text fontFamily={"Roboto Mono"} fontSize="sm" fontWeight={700}>
          {param.name}
        </Text>
        <Text
          fontFamily={"Open Sans"}
          fontWeight={"bold"}
          fontSize="xs"
          color={"grey"}
        >
          {param.schema.type}
        </Text>
      </Flex>
      <Text fontSize="sm" fontFamily={"Open Sans"} fontWeight={400}>
        {param.schema.description}
      </Text>
      {index < paramLength - 1 && <Divider my={4} />}
    </>
  );
}

export default RequiredParameter;
