import {
  Flex,
  Text,
  Divider,
  Box,
  IconButton,
  Heading,
  Tooltip,
  Collapse,
  Textarea,
} from "@chakra-ui/react";
import { ChevronRightIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import axios from "axios";
import RequiredParameter from "../common/parameters/RequiredParameter";
import OptionalParameter from "../common/parameters/OptionalParameter";
import OperationTabs from "./OperationTabs";

function OperationsPage({
  operation,
  url,
  baseUrl,
  request,
  authToken,
  setAuthToken,
  isDesktopOrLaptop,
}) {
  const [openParamIndex, setOpenParamIndex] = useState(null);
  const [openRequestParamIndex, setOpenRequestParamIndex] = useState(null);
  const [paramList, setParamList] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showRequestBody, setShowRequestBody] = useState(false);
  const [sampleRequestBody, setSampleRequestBody] = useState("");
  const [filteredPatchError, setFilteredPatchError] = useState("");
  // const [filteredPatchData, setFilteredPatchData] = useState("");
  const [filteredPostData, setFilteredPostData] = useState("");
  const [filteredPostError, setFilteredPostError] = useState("");

  useEffect(() => {
    if (operation?.parameters) {
      let tempParams = [];
      operation.parameters.forEach((parameter) => {
        tempParams.push({
          name: parameter.name,
          required: parameter.required,
          value: "",
        });
      });
      setParamList(tempParams);
    }
  }, [operation.parameters]);

  const createSampleRequestBody = (body) => {
    let sample = "{ \n ";
    Object.keys(body).map((propKey) => {
      sample += `"${propKey}"`;
      if (body[propKey]?.example) {
        sample += `: "${body[propKey].example}", \n `;
      } else if (body[propKey]?.type === "integer") {
        sample += `: 0, \n `;
      } else if (body[propKey]?.type === "string") {
        sample += `: "string", \n `;
      } else if (body[propKey]?.type === "array") {
        if (body[propKey]?.items?.properties) {
          sample += `: [ \n `;
          sample += createSampleRequestBody(body[propKey].items.properties);
          sample += `], \n `;
        }
      } else if (body[propKey]?.type === "object") {
        if (body[propKey]?.properties) {
          sample += `: `;
          sample += createSampleRequestBody(body[propKey].properties);
          sample += `, \n `;
        }
      }
    });
    sample = sample.replace(/,\s*$/, "");
    sample += `}`;
    return sample;
  };

  useEffect(() => {
    if (operation?.requestBody) {
      let requestBody = "";
      if (operation?.requestBody?.content) {
        Object.keys(operation.requestBody.content).map((key) => {
          if (operation.requestBody.content[key]?.schema?.properties) {
            requestBody = createSampleRequestBody(
              operation.requestBody.content[key].schema.properties
            );
          }
        });
      }
      setSampleRequestBody(requestBody);
    }
  }, [operation.requestBody]);

  const handleOpenParam = (index) => {
    if (index === openParamIndex) {
      setOpenParamIndex(null);
    } else {
      setOpenParamIndex(index);
    }
  };

  const handleOpenRequestParam = (index) => {
    if (index === openRequestParamIndex) {
      setOpenRequestParamIndex(null);
    } else {
      setOpenRequestParamIndex(index);
    }
  };

  const updateUrlWithRequiredParams = () => {
    let tempUrl = url;
    for (var i = 0; i < paramList.length; i++) {
      if (paramList[i].value !== "") {
        tempUrl = url.replace(
          new RegExp("{" + paramList[i].name + "}", "gi"),
          paramList[i].value
        );
      }
    }
    return tempUrl;
  };

  const getOptionalParamsObj = () => {
    let tempParams = {};
    paramList.map((item) => {
      if (
        !item.required ||
        item.required === null ||
        item.required === undefined
      ) {
        if (item.value !== "") {
          tempParams[`${item.name}`] = `${item.value}`;
        }
      }
    });
    return tempParams;
  };

  const useCustomQuery = () => {
    let tempUrl = updateUrlWithRequiredParams();
    let tempParams = getOptionalParamsObj();

    return useQuery(
      [tempUrl, paramList],
      () =>
        axios
          .get(baseUrl + tempUrl, {
            headers: {
              Authorization: "API-Token " + authToken,
            },
            params: tempParams,
          })
          .then((res) => res.data),
      {
        enabled: false,
      }
    );
  };

  const { data, isFetching, refetch: refetchQuery } = useCustomQuery();

  const {
    data: postData,
    error: postError,
    isSuccess: postSuccess,
    mutate: postMutate,
    isLoading: postFetching,
  } = useMutation(() => {
    let tempUrl = updateUrlWithRequiredParams();
    let tempParams = getOptionalParamsObj();
    return axios.post(
      baseUrl + tempUrl,
      sampleRequestBody.replace(/[\r\n]/gm, ""),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "API-Token " + authToken,
        },
        params: tempParams,
      }
    );
  });

  const {
    data: patchData,
    error: patchError,
    isSuccess: patchSuccess,
    mutate: patchMutate,
    isLoading: patchFetching,
  } = useMutation(() => {
    let tempUrl = updateUrlWithRequiredParams();
    let tempParams = getOptionalParamsObj();
    return axios.patch(baseUrl + tempUrl, sampleRequestBody.replace("\n", ""), {
      headers: {
        "Content-Type": "application/json",
        Authorization: "API-Token " + authToken,
      },
      params: tempParams,
    });
  }, [sampleRequestBody]);

  useEffect(() => {
    if (patchError) {
      let temp = patchError;
      if (temp?.config) {
        delete temp.config;
      }
      if (temp?.name) {
        delete temp.name;
      }
      setFilteredPatchError(temp);
    }
  }, [patchError]);

  useEffect(() => {
    if (postError) {
      let temp = postError;
      if (temp?.config) {
        delete temp.config;
      }
      if (temp?.name) {
        delete temp.name;
      }
      setFilteredPostError(temp);
    }
  }, [postError]);

  useEffect(() => {
    if (postData) {
      let temp = postData;
      if (temp?.config) {
        delete temp.config;
      }
      if (temp?.headers) {
        delete temp.headers;
      }
      if (temp?.request) {
        delete temp.request;
      }
      setFilteredPostData(temp);
    }
  }, [postData]);

  // useEffect(() => {
  //   let temp = patchData;
  //   if (temp?.config) {
  //     delete temp.config;
  //   }
  //   if (temp?.headers) {
  //     delete temp.headers;
  //   }
  //   if (temp?.request) {
  //     delete temp.request;
  //   }
  //   setFilteredPatchData(temp);
  // }, [patchData]);

  const handleSubmit = () => {
    if (request.toLowerCase() === "get") {
      refetchQuery();
    } else if (request.toLowerCase() === "post") {
      postMutate();
    } else if (request.toLowerCase() === "patch") {
      patchMutate();
    }
  };

  const handleParamChange = (newParam, event) => {
    const newState = paramList.map((obj) => {
      if (obj.name === newParam) {
        return { ...obj, value: event.target.value };
      }
      return obj;
    });
    setParamList(newState);
  };

  return (
    <Flex
      direction={isDesktopOrLaptop ? "row" : "column"}
      justifyContent="space-between"
      //todo this is broken
      // alignItems={isDesktopOrLaptop ? "flex-start" : "center"}
      alignItems={"flex-start"}
    >
      <Flex direction="column" padding={10} gap={7} flex={1}>
        <Heading size="md" fontFamily={"Open Sans"} fontWeight={"bold"}>
          {operation.summary}
        </Heading>
        <Text fontSize="md" fontFamily={"Open Sans"}>
          {operation.description}
        </Text>

        <Box>
          <Heading size="sm" fontFamily={"Open Sans"} fontWeight={"semibold"}>
            Required Parameters
          </Heading>
          <Divider my={3} width="100%" />
          <>
            {operation.parameters &&
            operation?.parameters.filter((param) => param.required === true)
              .length > 0 ? (
              <>
                {operation?.parameters
                  .filter((param) => param.required === true)
                  .map((param, index) => (
                    <RequiredParameter
                      param={param}
                      index={index}
                      paramLength={operation.parameters.length}
                    />
                  ))}
              </>
            ) : (
              <Text fontFamily={"Open Sans"} fontWeight={400} fontSize="sm">
                None
              </Text>
            )}
          </>
        </Box>

        <Box>
          <Heading size="sm" fontFamily={"Open Sans"} fontWeight={"semibold"}>
            Optional Parameters
          </Heading>
          <Divider my={3} width="100%" />
          <>
            {operation.parameters &&
            operation?.parameters.filter((param) => !param.required).length >
              0 ? (
              <>
                {operation?.parameters
                  .filter((param) => !param.required)
                  .map((param, index) => (
                    <OptionalParameter
                      param={param}
                      index={index}
                      openParamIndex={openParamIndex}
                      handleOpenParam={handleOpenParam}
                      paramLength={operation.parameters.length}
                    />
                  ))}
              </>
            ) : (
              <Text fontFamily={"Open Sans"} fontWeight={400} fontSize="sm">
                None
              </Text>
            )}
          </>
        </Box>
        {operation?.requestBody && (
          <Box>
            <Flex direction="row" gap={2} alignItems="baseline">
              <Heading
                size="sm"
                fontFamily={"Open Sans"}
                fontWeight={"semibold"}
              >
                Request Body
              </Heading>
              <Text
                fontFamily={"Open Sans"}
                fontWeight={"bold"}
                fontSize="sm"
                color={"grey"}
              >
                {operation.requestBody.required ? "required" : ""}
              </Text>
              <Text fontFamily={"Roboto Mono"} fontSize="sm" color={"grey"}>
                {Object.keys(operation.requestBody.content)[0]}
              </Text>
              <Tooltip label={"Show fully expanded request body"}>
                <IconButton
                  size="sm"
                  ml="auto"
                  onClick={() => setShowRequestBody((old) => !old)}
                  icon={
                    showRequestBody ? <ChevronDownIcon /> : <ChevronRightIcon />
                  }
                />
              </Tooltip>
            </Flex>
            <Divider my={3} />
            {showRequestBody && (
              <Textarea
                mt={3}
                mb={6}
                fontFamily={"Roboto Mono"}
                fontSize="sm"
                fontWeight={400}
                height={"30vh"}
                isReadOnly
                value={JSON.stringify(operation.requestBody, null, 2)}
              />
            )}
            <>
              {operation?.requestBody?.content && (
                <>
                  {Object.keys(operation.requestBody.content).map((key) => (
                    <>
                      {operation.requestBody.content[key]?.schema
                        ?.properties && (
                        <>
                          {Object.keys(
                            operation.requestBody.content[key]?.schema
                              ?.properties
                          ).map((paramKey, index) => (
                            <>
                              <Flex
                                gap={1}
                                align={"center"}
                                onClick={() => handleOpenRequestParam(index)}
                              >
                                {openRequestParamIndex === index ? (
                                  <ChevronDownIcon />
                                ) : (
                                  <ChevronRightIcon />
                                )}
                                <Text
                                  fontFamily={"Roboto Mono"}
                                  fontSize="sm"
                                  fontWeight={700}
                                >
                                  {paramKey}
                                </Text>
                                <Text
                                  fontFamily={"Open Sans"}
                                  fontWeight={"bold"}
                                  fontSize="xs"
                                  color={"grey"}
                                >
                                  {operation.requestBody.content[key]?.schema
                                    ?.properties[paramKey].type
                                    ? "nullable"
                                    : "non-nullable"}{" "}
                                  {
                                    operation.requestBody.content[key]?.schema
                                      ?.properties[paramKey].type
                                  }
                                </Text>
                              </Flex>
                              <Collapse
                                mt={4}
                                in={openRequestParamIndex === index}
                              >
                                <Text
                                  fontSize="sm"
                                  fontFamily={"Open Sans"}
                                  pl={4}
                                  fontWeight={400}
                                >
                                  {
                                    operation.requestBody.content[key]?.schema
                                      ?.properties[paramKey].description
                                  }
                                </Text>
                                {operation.requestBody.content[key]?.schema
                                  ?.properties[paramKey].example && (
                                  <Text
                                    pl={4}
                                    fontFamily={"Roboto Mono"}
                                    fontSize="sm"
                                    fontWeight={700}
                                  >
                                    Example:
                                    {
                                      operation.requestBody.content[key]?.schema
                                        ?.properties[paramKey].example
                                    }
                                  </Text>
                                )}
                              </Collapse>

                              <Divider my={4} />
                            </>
                          ))}
                        </>
                      )}
                    </>
                  ))}
                </>
              )}
            </>
          </Box>
        )}
        <Box>
          <Heading size="sm" fontFamily={"Open Sans"} fontWeight={"semibold"}>
            Returns
          </Heading>
          <Divider my={3} />
          <Text fontFamily={"Open Sans"} fontWeight={400} fontSize="sm">
            {operation.responses.summary ?? "Check response block"}
          </Text>
        </Box>
      </Flex>
      <OperationTabs
        isDesktopOrLaptop={isDesktopOrLaptop}
        url={url}
        request={request}
        operation={operation}
        sampleRequestBody={sampleRequestBody}
        setSampleRequestBody={setSampleRequestBody}
        showPassword={showPassword}
        authToken={authToken}
        setAuthToken={setAuthToken}
        setShowPassword={setShowPassword}
        paramList={paramList}
        handleParamChange={handleParamChange}
        handleSubmit={handleSubmit}
        patchSuccess={patchSuccess}
        patchData={patchData}
        postSuccess={postSuccess}
        isFetching={isFetching}
        postFetching={postFetching}
        patchFetching={patchFetching}
        data={data}
        filteredPatchError={filteredPatchError}
        filteredPostData={filteredPostData}
        filteredPostError={filteredPostError}
      />
    </Flex>
  );
}

export default OperationsPage;
