import "./App.css";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import {
  ChakraProvider,
  Divider,
  Flex,
  Text,
  IconButton,
  Select,
  useDisclosure,
  useColorMode,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import Sidebar from "./sidebar";
import theme from "./theme";
import "@fontsource/open-sans/400.css";
import OperationsPage from "./components/operations/OperationsPage";
import SchemaPage from "./components/schemas/SchemaPage";
import TitlePage from "./components/common/TitlePage";
import { useEffect, useState, useRef } from "react";
import HeaderPage from "./components/common/HeaderPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import SearchModal from "./components/common/SearchModal";
import { useMediaQuery } from "react-responsive";
import { useLocation } from 'react-router-dom';
import customTheme from "./customTheme";
const SwaggerParser = require("@apidevtools/swagger-parser");

function CustomLayout({ getComponent }) {
  const Sidebar = getComponent("SidebarLayout", true);
  const BaseLayout = getComponent("BaseLayout", true);
  const [api, setApi] = useState(null);
  const [authToken, setAuthToken] = useState("");
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const schemasRef = useRef([]);
  const operationsRef = useRef([]);
  const headerRef = useRef(null);
  const queryClient = new QueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const schemaCopy = "Entities";
  const operationsCopy = "Resources";
  const introCopy = "Introduction";
  const { colorMode } = useColorMode();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const scrollToTerm = (term) => {
    if (api?.components["schemas"]) {
      Object.keys(api.components["schemas"]).forEach((schema, index) => {
        if (term === schemaCopy) {
          handleSearchSelection("schema", 0);
          return;
        } else if (term === schema) {
          handleSearchSelection("schema", index);
          return;
        }
      });
    }
    if (api?.tags) {
      api.tags.forEach((tag, index) => {
        if (term === operationsCopy) {
          handleSearchSelection("endpoint", 0);
          return;
        } else if (term === tag.name) {
          handleSearchSelection("endpoint", index);
          return;
        }
      });
    }
    if (api?.info?.description) {
      let description = api.info.description;
      const headings = description
        ?.match(/#{1,6}.+(?=\n)/g)
        .join("\n")
        .split("\n")
        .map((header) => header.replaceAll("# ", "").replaceAll("#", ""));
      headings.forEach((heading) => {
        if (term === heading || term === introCopy) {
          handleSearchSelection("header");
          return;
        }
      });
    }
  };

  let myAPI = "./openapi.yaml";

  useEffect(() => {
    if(window.location.pathname.toLowerCase() === '/v2')
      myAPI = "./v2.openapi.yaml";
    else
      myAPI = './openapi.yaml';
  }, [window.location.pathname, myAPI]);

  useEffect(() => {
    let loc = window.location.href;
    if (loc.split("#") && loc.split("#").length > 1) {
      loc = loc.split("#")[1].replace("%20", " ");
    }
    if (loc) {
      scrollToTerm(loc);
    }
  }, [window.location.href, api]);

  useEffect(() => {
    SwaggerParser.validate(myAPI, (err, api) => {
      if (err) {
        console.error(err);
      } else {
        setApi(api);
        schemasRef.current = schemasRef.current.slice(
          0,
          Object.keys(api.components["schemas"]).length
        );
        operationsRef.current = operationsRef.current.slice(0, api.tags.length);
        // console.log(
        //   "API name: %s, Version: %s",
        //   api.info.title,
        //   api.info.version,
        //   api
        // );s
      }
    });
  }, [myAPI]);

  const handleSelect = (e) => {
    scrollToTerm(e.target.value);
  };

  const handleSearchSelection = (type, index) => {
    onClose();
    if (type === "endpoint") {
      operationsRef.current[index].scrollIntoView();
    } else if (type === "schema") {
      schemasRef.current[index].scrollIntoView();
    } else if (type === "header") {
      headerRef.current.scrollIntoView();
    }
  };

  return (
    <QueryClientProvider client={queryClient}>
      <SearchModal
        api={api}
        isOpen={isOpen}
        onClose={onClose}
        handleSearchSelection={handleSearchSelection}
      />
      <Flex>
        {api && isDesktopOrLaptop && (
          <Sidebar
            api={api}
            headerRef={headerRef}
            schemasRef={schemasRef}
            operationsRef={operationsRef}
            handleSearchModal={onOpen}
            schemaCopy={schemaCopy}
            operationsCopy={operationsCopy}
            introCopy={introCopy}
          />
        )}
        <div
          style={{
            height: "100vh",
            width: isDesktopOrLaptop ? "80%" : "100%",
            top: 0,
            bottom: 0,
            overflowY: "scroll",
          }}
        >
          {api && (
            <div>
              {!isDesktopOrLaptop && (
                <Flex
                  direction="row"
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  padding={3}
                  borderBottom={"1px solid"}
                  borderBottomColor="grey"
                  style={{
                    position: "sticky",
                    top: "0px",
                    backgroundColor:
                      colorMode === "light"
                        ? customTheme.colors.lightMode.background
                        : customTheme.colors.darkMode.background,
                  }}
                >
                  <Select
                    width="25%"
                    variant={"unstyled"}
                    onChange={handleSelect}
                  >
                    {api?.info?.description
                      ?.match(/#{1,6}.+(?=\n)/g)
                      .join("\n")
                      .split("\n")
                      .map((header) =>
                        header.replaceAll("# ", "").replaceAll("#", "")
                      )
                      .map((header) => (
                        <option
                          value={header}
                          onClick={() => handleSearchSelection("header")}
                        >
                          {header}
                        </option>
                      ))}
                    {api.tags.map((tag, index) => (
                      <option
                        value={tag.name}
                        onClick={(index) =>
                          handleSearchSelection("endpoint", index)
                        }
                      >
                        {tag.name}
                      </option>
                    ))}
                    {Object.keys(api.components["schemas"]).map(
                      (schema, index) => (
                        <option
                          value={schema}
                          onClick={() => handleSearchSelection("schema", index)}
                        >
                          {schema}
                        </option>
                      )
                    )}
                  </Select>
                  <IconButton onClick={onOpen} icon={<SearchIcon />} />
                </Flex>
              )}
              <HeaderPage
                title={api.info.title}
                description={api.info.description}
                descriptionMd={true}
                blockTitle={"BASE URL"}
                id="#introduction"
                blockContent={api?.servers[0]?.url}
                apiVersion={api.info.version}
                openApiVersion={api.openapi}
                innerRef={headerRef}
                isDesktopOrLaptop={isDesktopOrLaptop}
              />
              {api.tags.map((tag, index) => (
                <div key={tag.name}>
                  <TitlePage
                    title={tag.name}
                    description={tag.description}
                    innerRef={(el) => (operationsRef.current[index] = el)}
                    blockTitle="ENDPOINTS"
                    isDesktopOrLaptop={isDesktopOrLaptop}
                    blockContent={Object.keys(api["paths"])
                      .map((pathKey) => {
                        if (
                          Object.values(api["paths"][pathKey]).filter((value) =>
                            value.tags.includes(tag.name)
                          ).length > 0
                        )
                          return pathKey + "\n";
                      })
                      .join("")}
                  />
                  {Object.keys(api["paths"]).map((pathKey) => (
                    <>
                      {Object.keys(api["paths"][pathKey]).map((requestKey) => (
                        <>
                          {api["paths"][pathKey][requestKey].tags.includes(
                            tag.name
                          ) && (
                            <>
                              <OperationsPage
                                operation={api["paths"][pathKey][requestKey]}
                                url={pathKey}
                                baseUrl={api?.servers[0]?.url}
                                request={requestKey}
                                authToken={authToken}
                                setAuthToken={setAuthToken}
                                isDesktopOrLaptop={isDesktopOrLaptop}
                              />
                              <Divider />
                            </>
                          )}
                        </>
                      ))}
                    </>
                  ))}
                </div>
              ))}
              <TitlePage
                title={"Schemas"}
                description={"A list of all available schemas"}
                isDesktopOrLaptop={isDesktopOrLaptop}
              />
              {Object.values(api.components["schemas"]).map((schema, index) => (
                <SchemaPage
                  schema={schema}
                  key={Object.keys(api.components["schemas"]).at(index)}
                  innerRef={(el) => (schemasRef.current[index] = el)}
                  title={Object.keys(api.components["schemas"]).at(index)}
                  isDesktopOrLaptop={isDesktopOrLaptop}
                />
              ))}
            </div>
          )}
          {/* <BaseLayout /> */}
        </div>
      </Flex>
    </QueryClientProvider>
  );
}

function SidebarLayout({
  specActions,
  specSelectors,
  getComponent,
  oas3Selectors,
  api,
  schemaCopy,
  operationsCopy,
  introCopy,
  headerRef,
  schemasRef,
  operationsRef,
  handleSearchModal,
}) {
  return (
    <Sidebar
      specActions={specActions}
      specSelectors={specSelectors}
      getComponent={getComponent}
      api={api}
      schemaCopy={schemaCopy}
      operationsCopy={operationsCopy}
      introCopy={introCopy}
      headerRef={headerRef}
      oas3Selectors={oas3Selectors}
      schemasRef={schemasRef}
      operationsRef={operationsRef}
      handleSearchModal={handleSearchModal}
    />
  );
}

const CustomPlugin = function (system) {
  return {
    components: {
      CustomLayout: CustomLayout,
    },
  };
};

const SidebarPlugin = function (system) {
  return {
    components: {
      SidebarLayout: SidebarLayout,
    },
  };
};

function App() {

  const isV2 = window.location.pathname.toLowerCase() === '/v2';
  const url = isV2 ? 'v2.openapi.yaml' : 'openapi.yaml';

  return (
    <ChakraProvider theme={theme}>
      <SwaggerUI
        url={url}
        plugins={[CustomPlugin, SidebarPlugin]}
        layout="CustomLayout"
      />
    </ChakraProvider>
  );
}

export default App;
