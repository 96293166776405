import { Text, Flex, Divider, FormControl, Input } from "@chakra-ui/react";

function ParameterField({ param, paramList, handleParamChange }) {
  return (
    <>
      <Flex direction="row" alignContent="center">
        <Flex direction="column" flex={1}>
          <Flex gap={1} align={"center"}>
            <Text fontFamily={"Roboto Mono"} fontSize="sm" fontWeight={700}>
              {param.name}
            </Text>
            <Text
              fontFamily={"Open Sans"}
              fontWeight={"bold"}
              fontSize="xs"
              color={"grey"}
            >
              {param.schema.type}
            </Text>
          </Flex>
          <Text fontSize="sm" fontFamily={"Open Sans"} fontWeight={400}>
            {param.schema.description}
          </Text>
        </Flex>
        <Flex flex={1}>
          <FormControl>
            {paramList.find((item) => item.name === param.name) && (
              <Input
                value={paramList.find((item) => item.name === param.name).value}
                onChange={(event) => handleParamChange(param.name, event)}
              />
            )}
          </FormControl>
        </Flex>
      </Flex>
      <Divider />
    </>
  );
}

export default ParameterField;
