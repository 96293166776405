import { Flex, Text, Divider, Box, Heading, Collapse } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ResponseBlock from "../common/ResponseBlock";

function SchemaPage({ schema, title, innerRef, isDesktopOrLaptop }) {
  const [example, setExample] = useState("No example found");

  useEffect(() => {
    if (schema.properties) {
      let text = "{ \n";
      let exampleFound = false;
      Object.keys(schema.properties).map((key) => {
        if (schema.properties[key]?.example) {
          exampleFound = true;
          text += `"${key}": `;
          text +=
            schema.properties[key]?.type === "string"
              ? `"${schema.properties[key]?.example}" \n `
              : `${schema.properties[key]?.example} \n `;
        }
      });
      text += "}";
      if (exampleFound) {
        setExample(text);
      }
    } else if (schema?.allOf) {
      let text = "{ \n";
      let exampleFound = false;
      schema.allOf.map((item) => {
        if (item.properties) {
          Object.keys(item.properties).map((key) => {
            if (item.properties[key]?.example) {
              exampleFound = true;
              text += `"${key}": `;
              text +=
                item.properties[key]?.type === "string"
                  ? `"${item.properties[key]?.example}" \n `
                  : `${item.properties[key]?.example} \n `;
            }
          });
        }
      });
      text += "}";
      if (exampleFound) {
        setExample(text);
      }
    } else if (schema?.items?.properties) {
      let text = "{ \n";
      let exampleFound = false;
      Object.keys(schema.items.properties).map((key) => {
        if (schema.items.properties[key]?.example) {
          exampleFound = true;
          text += `"${key}": `;
          text +=
            schema.items.properties[key]?.type === "string"
              ? `"${schema.items.properties[key]?.example}" \n `
              : `${schema.items.properties[key]?.example} \n `;
        }
      });
      text += "}";
      if (exampleFound) {
        setExample(text);
      }
    }
  }, [schema]);

  return (
    <Flex
      // direction={isDesktopOrLaptop ? "row" : "column"}
      direction="row"
      justifyContent="space-between"
      ref={innerRef}
    >
      <Flex direction="column" padding={10} gap={7} flex={1}>
        <Flex direction="row" alignItems={"baseline"} gap={3}>
          <Heading size="md" fontFamily={"Open Sans"} fontWeight={"bold"}>
            {title}
          </Heading>
          {schema.type && (
            <Text
              fontSize="md"
              fontFamily={"Open Sans"}
              fontWeight={"bold"}
              color={"grey"}
            >
              {schema.type}
            </Text>
          )}
        </Flex>
        <div>
          {schema.description && (
            <Text fontSize="md" fontFamily={"Open Sans"}>
              {schema.description}
            </Text>
          )}
          {schema.example && (
            <Text fontSize="sm" fontFamily={"Roboto Mono"}>
              Example: {schema.example}
            </Text>
          )}
        </div>
        <Flex
          direction={isDesktopOrLaptop ? "row" : "column"}
          // justifyContent="space-between"
        >
          <Box flex={1} paddingRight={3}>
            <Heading size="sm" fontFamily={"Open Sans"} fontWeight={"semibold"}>
              Properties
            </Heading>
            <Divider my={3} width="100%" />
            <>
              {schema.properties && (
                <>
                  {Object.keys(schema.properties).map((key) => (
                    <>
                      <Flex gap={1} align={"center"}>
                        <Text
                          fontFamily={"Roboto Mono"}
                          fontSize="sm"
                          fontWeight={700}
                        >
                          {key}
                        </Text>
                        <Text
                          fontFamily={"Open Sans"}
                          fontWeight={"bold"}
                          fontSize="xs"
                          color={"grey"}
                        >
                          {schema.properties[key].nullable && "nullable "}
                          {schema.properties[key].type}
                        </Text>
                      </Flex>
                      <Text
                        fontSize="sm"
                        fontFamily={"Open Sans"}
                        fontWeight={400}
                      >
                        {schema.properties[key].description}
                      </Text>
                      {schema.properties[key].example && (
                        <Text
                          fontSize="sm"
                          fontFamily={"Open Sans"}
                          fontWeight={400}
                        >
                          {`Example: ${schema.properties[key].example}`}
                        </Text>
                      )}
                      <Divider my={4} />
                    </>
                  ))}
                </>
              )}
              {schema?.items?.properties && (
                <>
                  {Object.keys(schema.items.properties).map((key) => (
                    <>
                      <Flex gap={1} align={"center"}>
                        <Text
                          fontFamily={"Roboto Mono"}
                          fontSize="sm"
                          fontWeight={700}
                        >
                          {key}
                        </Text>
                        <Text
                          fontFamily={"Open Sans"}
                          fontWeight={"bold"}
                          fontSize="xs"
                          color={"grey"}
                        >
                          {schema.items.properties[key].nullable && "nullable "}
                          {schema.items.properties[key].type}
                        </Text>
                      </Flex>
                      <Text
                        fontSize="sm"
                        fontFamily={"Open Sans"}
                        fontWeight={400}
                      >
                        {schema.items.properties[key].description}
                      </Text>
                      {schema.items.properties[key].example && (
                        <Text
                          fontSize="sm"
                          fontFamily={"Open Sans"}
                          fontWeight={400}
                        >
                          {`Example: ${schema.items.properties[key].example}`}
                        </Text>
                      )}
                      <Divider my={4} />
                    </>
                  ))}
                </>
              )}
              {schema.allOf &&
                schema.allOf.map((item) => (
                  <>
                    {item.properties &&
                      Object.keys(item.properties).map((key) => (
                        <>
                          <Flex gap={1} align={"center"}>
                            <Text
                              fontFamily={"Roboto Mono"}
                              fontSize="sm"
                              fontWeight={700}
                            >
                              {key}
                            </Text>
                            <Text
                              fontFamily={"Open Sans"}
                              fontWeight={"bold"}
                              fontSize="xs"
                              color={"grey"}
                            >
                              {item.properties[key].nullable && "nullable "}
                              {item.properties[key].type}
                            </Text>
                          </Flex>
                          <Text
                            fontSize="sm"
                            fontFamily={"Open Sans"}
                            fontWeight={400}
                          >
                            {item.properties[key].description}
                          </Text>
                          {item.properties[key].example && (
                            <Text
                              fontSize="sm"
                              fontFamily={"Roboto Mono"}
                              fontWeight={400}
                            >
                              {`Example: ${item.properties[key].example}`}
                            </Text>
                          )}
                          <Divider my={4} />
                        </>
                      ))}
                  </>
                ))}
            </>
          </Box>
          <Flex
            direction="column"
            flex={1}
            gap={3}
            padding={3}
            alignSelf={isDesktopOrLaptop ? "flex-start" : "center"}
          >
            <ResponseBlock title={"EXAMPLE"} response={example} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SchemaPage;
