import { Text, Flex, Divider, Collapse } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";

function OptionalParameter({
  param,
  index,
  openParamIndex,
  handleOpenParam,
  paramLength,
}) {
  return (
    <>
      <Flex gap={1} align={"center"}>
        <Flex
          direction="row"
          onClick={() => handleOpenParam(index)}
          alignItems="center"
        >
          {openParamIndex === index ? (
            <ChevronDownIcon />
          ) : (
            <ChevronRightIcon />
          )}
          <Text fontFamily={"Roboto Mono"} fontSize="sm" fontWeight={700}>
            {param.name}
          </Text>
        </Flex>
        <Text
          fontFamily={"Open Sans"}
          fontWeight={"bold"}
          fontSize="xs"
          color={"grey"}
        >
          {param.schema.type}
        </Text>
      </Flex>
      <Collapse mt={4} in={openParamIndex === index}>
        <Text
          mt={2}
          ml={4}
          fontSize="sm"
          fontFamily={"Open Sans"}
          fontWeight={400}
        >
          {param.description}
        </Text>
      </Collapse>

      {index < paramLength - 1 && <Divider my={4} />}
    </>
  );
}

export default OptionalParameter;
