import React from "react";
import PropTypes from "prop-types";
import Im, { Map } from "immutable";
import { useState } from "react";
import customTheme from "./customTheme";
import {
  Image,
  Text,
  Button,
  Flex,
  Switch,
  useColorMode,
  IconButton,
} from "@chakra-ui/react";
import {
  SearchIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
// const SwaggerParser = require("@apidevtools/swagger-parser");

function Sidebar({
  specActions,
  specSelectors,
  getComponent,
  oas3Selectors,
  api,
  schemaCopy,
  operationsCopy,
  introCopy,
  headerRef,
  schemasRef,
  operationsRef,
  handleSearchModal,
}) {
  const { colorMode, toggleColorMode } = useColorMode();

  const getSchemaBasePath = () => {
    const isOAS3 = specSelectors.isOAS3();
    return isOAS3 ? ["components", "schemas"] : ["definitions"];
  };

  // The "Introduction" part of the sidebar
  let description = api.info.description;
  const headings = description
    ?.match(/#{1,6}.+(?=\n)/g)
    .join("\n")
    .split("\n"); // gets all markdown headings

  function removeHashtags(string) {
    return string.replaceAll("# ", "").replaceAll("#", "");
  }

  let formattedHeadings = [
    {
      heading: introCopy,
      subheadings: headings.map((item) => removeHashtags(item)),
    },
  ];

  // The "Operations" part of the sidebar
  const operations = specSelectors
    .taggedOperations()
    .map((useless, tag) => {
      return tag;
    })
    .toArray();

  // The "Schema" part of the sidebar
  let definitions = specSelectors.definitions();
  let models = definitions
    .entrySeq()
    .map(([name]) => {
      const specPathBase = getSchemaBasePath();
      const fullPath = [...specPathBase, name];
      const schemaValue = specSelectors.specResolvedSubtree(fullPath);
      const rawSchemaValue = specSelectors.specJson().getIn(fullPath);

      const schema = Map.isMap(schemaValue) ? schemaValue : Im.Map();
      const rawSchema = Map.isMap(rawSchemaValue) ? rawSchemaValue : Im.Map();

      const displayName = schema.get("title") || rawSchema.get("title") || name;

      return displayName;
    })
    .toArray();

  return (
    <SidebarContainer>
      <div
        style={{
          position: "sticky",
          top: "0px",
          backgroundColor:
            colorMode === "light"
              ? customTheme.colors.lightMode.background
              : customTheme.colors.darkMode.background,
          paddingTop: "20px",
        }}
      >
        <Flex direction="row" justifyContent="space-between">
          <Image
            width={"75%"}
            src="/paperless-parts-full-logo-2022.svg"
            alt="Paperless Parts Logo"
          />
          <Switch size="sm" onChange={toggleColorMode} />
        </Flex>
        <Button
          variant="outline"
          my={2}
          size="sm"
          width="100%"
          onClick={handleSearchModal}
          style={{ borderRadius: 10 }}
          justifyContent="flex-start"
          leftIcon={<SearchIcon />}
        >
          <Text
            fontSize="xs"
            fontFamily={"Open Sans"}
            fontWeight={"bold"}
            color={customTheme.colors.lightMode.mediumText}
          >
            Find Anything
          </Text>
        </Button>
      </div>
      {formattedHeadings.map((heading, idx) => (
        <SidebarHeading
          text={removeHashtags(heading.heading)}
          subheadings={heading.subheadings}
          key={idx}
          scrollRefs={headerRef}
        />
      ))}
      {operations && (
        <SidebarHeading
          text={operationsCopy}
          subheadings={operations}
          scrollRefs={operationsRef}
        />
      )}
      {models && (
        <SidebarHeading
          text={schemaCopy}
          subheadings={models}
          scrollRefs={schemasRef}
        />
      )}
    </SidebarContainer>
  );
}

export default Sidebar;

/* --------------------------------- Styles --------------------------------- */

const headingStyles = {
  display: "flex",
  flexDirection: "row",
  marginTop: 20,
  alignItems: "center",
  justifyContent: "space-between",
  fontWeight: 600,
};

const headingLinkStyles = {
  fontSize: 14,
  textDecoration: "none",
};

const subheadingLinkStyles = {
  fontSize: 14,
  textDecoration: "none",
};

/* ------------------------------- Components ------------------------------- */

const SidebarHeading = ({ text, subheadings, scrollRefs }) => {
  const [isHeadingExpanded, setIsHeadingExpanded] = useState(true);

  const handleScroll = (index) => {
    if (text == "Introduction") {
      scrollRefs.current.scrollIntoView();
    } else {
      scrollRefs.current[index].scrollIntoView();
    }
  };

  return (
    <div>
      <div style={headingStyles} onClick={() => handleScroll(0)}>
        <a href={`#${text}`} style={headingLinkStyles}>
          {text}
        </a>

        {subheadings.length > 0 &&
          (isHeadingExpanded ? (
            <IconButton
              variant="unstyled"
              onClick={() => setIsHeadingExpanded(!isHeadingExpanded)}
              icon={<ChevronDownIcon />}
            />
          ) : (
            <IconButton
              variant="unstyled"
              onClick={() => setIsHeadingExpanded(!isHeadingExpanded)}
              icon={<ChevronRightIcon />}
            />
          ))}
      </div>

      {isHeadingExpanded &&
        subheadings?.map((subheading, idx) => {
          return (
            <SidebarSubheading
              text={subheading}
              key={idx}
              index={idx}
              handleScroll={handleScroll}
            />
          );
        })}
    </div>
  );
};

SidebarHeading.propTypes = {
  text: PropTypes.string,
  subheadings: PropTypes.array,
};

const SidebarSubheading = ({ text, index, handleScroll }) => (
  <div style={{ marginTop: 10 }} onClick={() => handleScroll(index)}>
    <a href={`#${text}`} style={subheadingLinkStyles}>
      {text}
    </a>
  </div>
);

SidebarSubheading.propTypes = {
  text: PropTypes.string,
};

const SidebarContainer = ({ children }) => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <div
      style={{
        maxWidth: "20%",
        borderRight: "1px solid",
        borderRightColor:
          colorMode === "light"
            ? customTheme.colors.lightMode.mediumBorder
            : customTheme.colors.darkMode.mediumBorder,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        height: "100vh",
        top: 0,
        bottom: 0,
        overflowY: "scroll",
      }}
    >
      {children}
    </div>
  );
};

SidebarContainer.propTypes = {
  children: PropTypes.any,
};
